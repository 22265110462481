import "./lib/jquery.mask";
import Pristine from "pristinejs";
import PragmaModal from "./lib/pragmatica/pragmaPopup";
// import magnificPopup from "magnific-popup";

export default function modals() {
  (function selectCity() {
    const cityTriggers = document.querySelectorAll("[data-modal-open]");

    cityTriggers.forEach((trigger) => {
      const modal = new PragmaModal(trigger);
    });
  })();

  window.Pristine = Pristine;
  const fixHack = Pristine;

  $(".inputPhone").mask("+7 (000) 000-00-00", { clearIfNotMatch: true });
}
