// import slick from 'slick-carousel';
import Swiper, {
    Navigation,
    Pagination
  } from "swiper";

export default function sliders() {

    Swiper.use([Navigation]);

    (function indexSlider() {
        const swiper = new Swiper('.index-slider', {
            keyboard: true,
            speed: 800,
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            }
        });
    })();

    (function simpleSlider() {
        const slidersList = document.querySelectorAll('.simple-slider');

        slidersList.forEach(item => {

            const paginationWrapper = item.querySelector('.swiper-pagination')

            const simple = new Swiper(item, {
                slidesPerView: item.dataset.slide || 4,
                watchOverflow: true,
                spaceBetween: 20,
                navigation: {
                    prevEl: '.prev-slide',
                    nextEl: '.next-slide'
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                breakpoints: {
                // when window width is <= 499px
                320: {
                    slidesPerView: 1,
                    spaceBetween: 0,
                    pagination: {
                        el: '.swiper-pagination',
                        type: 'bullets',
                        clickable: true
                    },
                },
                // when window width is <= 999px
                768: {
                    spaceBetween: 10,
                    slidesPerView: 2,
                },
                992: {
                    pagination: false,
                    spaceBetween: 20,
                    slidesPerView: item.dataset.slide || 4
                },
                },
            });
            simple.on('slideChange', function () {
                simple.pagination.update();
            });
        })
    })();

    // (function thumbnailSlider() {
    //     const slidersList = document.querySelectorAll('.product-card-thumbnails');

    //     slidersList.forEach(item => {

    //         const simple = new Swiper('.product-card-thumbnails', {
    //             slidesPerView: item.dataset.slide || 5,
    //             direction: 'vertical',
    //             watchOverflow: true,
    //             spaceBetween: 20,
    //             navigation: {
    //                 prevEl: '.prev-image',
    //                 nextEl: '.next-image'
    //             },

    //               breakpoints: {
    //                 // when window width is <= 499px
    //                 320: {
    //                   slidesPerView: 3,
    //                   spaceBetween: 15,
    //                   direction: 'horizontal'
    //                 },
    //                 // when window width is <= 999px
    //                 768: {
    //                     spaceBetween: 20,
    //                     slidesPerView: 3,
    //                     direction: 'horizontal'
    //                 },
    //                 992: {
    //                     spaceBetween: 20,
    //                     slidesPerView: item.dataset.slide || 5,
    //                     direction: 'vertical'
    //                 },
    //               },
    //         });
    //     })
    // })();


}
