/* eslint-disable func-names */
/* eslint-disable object-shorthand */
import { Swiper } from "swiper";
import "easy-autocomplete";
import sliders from "./sliders";
import modals from "./modals";
import controls from "./controls";
// import animation from './animation';

global.jQuery = $;
global.$ = $;

global.ww = window.innerWidth;
global.wh = window.innerHeight;

$(document).ready(() => {
  document.body.className += " ready";
  global.mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  controls();
  sliders();
  modals();
  // animation()

  const jsonUrl = $("#search-head").data("url");
  $("#search-head").easyAutocomplete({
    url: function (phrase) {
      return `${jsonUrl}?phrase=${phrase}&format=json`;
    },
    getValue: "name",
    cssClasses: "search-head",
    template: {
      type: "custom",
      method(value, item) {
        return `<a href='${item.uri}' ><span class='search-img'><img src='${item.icon}' /></span><span class='search-name'>${value}</span><span class='search-cost'>${item.cost}</span></a>`;
      },
    },
    list: {
      maxNumberOfElements: 10,
      match: {
        enabled: true,
      },
      sort: {
        enabled: true,
      },
    },
  });
  $("#search-form").on("submit", function (e) {
    e.preventDefault();
    window.location = `/search?q=${$("#search-head").val()}`;
  });

  const allResponsives = () => {
    global.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

    console.log("allResponsives!");
  };
  allResponsives();

  $(window).on("resize", function () {
    global.ww = window.innerWidth;
    global.wh = window.innerHeight;
    allResponsives();
  });
});
