/* eslint-disable */
// import { select2 } from 'select2';
import {selectize} from "./lib/selectize";
import "bootstrap/js/dist/tab";

import "../sass/lib/semantic/semantic";
import ionRangeSlider from "ion-rangeslider";

export default function controls() {
  // mobile menu
  (function mobileMenu() {
    let header = document.querySelector(".header_mobile");
    if (!header) return false;
    let button = header.querySelector(".mmenu-toggle");
    let modal = header.querySelector(".mmenu-list");
    let overlay = header.querySelector(".menu-overlay");

    button.addEventListener("click", function () {
      let active = header.classList.toggle("mmenu-open");
    });
    overlay.addEventListener("click", function () {
      let active = header.classList.remove("mmenu-open");
    });
  })();


  (function catalogList() {
    let btn = document.querySelector('.header-catalog')
    let list = document.querySelector('.catalog-dropdown')
    let showed = false;

    function checkOut(e) {
      if(!showed) return;
      if(e.target.classList.contains('catalog-dropdown') || e.target.closest('.catalog-dropdown') || e.target.classList.contains('header-catalog') || e.target.closest('.catalog-dropdown')) return;
      showed = false;
      list.classList.remove('showed');
      btn.classList.remove('active');
      document.body.removeEventListener('click', checkOut);
      
    }

    btn.addEventListener('click', (e) => {
      if(e.target.tagName === "B") return;
      e.preventDefault();
      if(!showed) {
        showed = true
        list.classList.add('showed');
        btn.classList.add('active');
        document.body.addEventListener('click', checkOut)
      } else {
        showed = false
        list.classList.remove('showed');
        btn.classList.remove('active');
        document.body.removeEventListener('click', checkOut)
      }
      
    })

  })();

  (function toolbar() {
    let toolbarDiv = document.querySelector(".toolbar-menu");
    if (!toolbarDiv && window.innerWidth >= 992) return false;

    let pSize = toolbarDiv.clientHeight;

    document.querySelector(".footer").style.paddingBottom = `${pSize}px`;
    document.querySelector(".mmenu-list").style.paddingBottom = `${pSize}px`;
  })();

  (function searchToggle() {
    let searchBtn = document.querySelector(".search-toggle");
    let searchMobile = document.querySelector(".header-mobile__search");
    if (!searchBtn) return false;

    searchBtn.addEventListener("click", () => {
      searchMobile.classList.toggle("d-none");
    });
  })();

  (function liked() {
    let likeButtons = document.querySelectorAll("[data-like]");
    if (!likeButtons) return false;

    likeButtons.forEach((btn) => {
      btn.addEventListener("click", () => {
        let liked = btn.classList.toggle("liked");
      });
    });
  })();

  $(".selectize").selectize();

  $("[data-range-slider]").ionRangeSlider({
    skin: "round",
  });

  (function filterRange() {
    let inputFrom = document.querySelector("#costFrom");
    let inputTo = document.querySelector("#costTo");
    if (!inputFrom || !inputTo) return false;

    $("[data-range-filter]").ionRangeSlider({
      skin: "round",
    });

    $("[data-range-filter]").on("change", function () {
      let $inp = $(this); // input data-to attribute
      inputFrom.value = $inp.data("from");
      inputTo.value = $inp.data("to");
    });

    let range = $("[data-range-filter]").data("ionRangeSlider");
    function rangeUpdate() {
      console.log(range);
      if (
        !inputFrom.value ||
        inputFrom.value < range.options.min ||
        inputFrom.value > range.options.max
      ) {
        inputFrom.value = range.options.min;
      }

      if (
        !inputTo.value ||
        inputTo.value < range.options.min ||
        inputTo.value > range.options.max
      ) {
        inputTo.value = range.options.max;
      }

      range.update({
        from: inputFrom.value || range.old_from,
        to: inputTo.value || range.old_to,
      });
    }
    inputFrom.addEventListener("change", rangeUpdate);

    inputTo.addEventListener("change", rangeUpdate);
  })();

  $(".ui.dropdown").dropdown({
    transition: "slide down",
  });

  $(".ui.checkbox").checkbox();

  $(".ui.accordion").accordion();

  $(".ui.checkbox input[name='delivery']").on("change", function () {
    if ($(this).attr("id") === "deliveryCompany") {
      $(".deliveryCompany").slideDown(300);
    } else {
      $(".deliveryCompany").slideUp(300);
    }
    if ($(this).attr("id") === "paymentAtPickup") {
      $(".paymentAtPickup").show();
    } else {
      $(".paymentAtPickup").hide();
      $("#payOnline").trigger("click");
    }
    if ($(this).attr("id") === "fastCityDelivery") {
      $(".fastCityDelivery").slideDown(300);
    } else {
      $(".fastCityDelivery").slideUp(300);
    }
  });

  $(".filter .filter-open").popup({
    on: "click",
    transition: "slide down",
    position: "bottom left",
    delay: {
      show: 300,
      hide: 800,
    },
  });

  $(".order-details").on("click", function () {
    $(this).closest(".order-item").toggleClass("opened");
    $(this)
      .closest(".order-item")
      .find(".order-content")
      .stop()
      .slideToggle(200);
  });

  $(".header-user").mouseover(function () {
    $(".header-user-menu").stop().slideDown("300");
  });
  $(".header-user").mouseout(function () {
    $(".header-user-menu").stop().slideUp("300");
  });

  $(".mmenu").on("click", function () {
    if (!$(this).hasClass("menuOpen")) {
      $(this).addClass("menuOpen");
      $(".header").addClass("menuActive");
    } else {
      $(this).removeClass("menuOpen");
      $(".header").removeClass("menuActive");
    }
  });
  $(".mmenu-list-nav a").on("click", function () {
    $(".mmenu").removeClass("menuOpen");
    $(".header").removeClass("menuActive");
  });

  // $('.js-select2').select2({
  //     minimumResultsForSearch: -1,
  //     width: '100%'
  // });

  $(".product-card-thumbnails__item").on("click", function () {
    let images = document.querySelectorAll('.product-card-thumbnails__item')

    images.forEach((pic) => {
      pic.classList.remove('active')
    })

    $(this).addClass('active')

    const newSrc = $(this).find("img").attr("src");
    $("#srcSet")[0].src = newSrc;
  });

  setTimeout(function () {
    $(".video-player").click(function () {
      const video = $(this)[0];
      const button = $(this).parent();
      if (video.paused) {
        video.play();
        button.addClass("playing");
      } else {
        video.pause();
        button.removeClass("playing");
      }
    });
  }, 500);

  $(document).ready(function () {
    $(".input-number input").on("change", function () {
      let val = $(this)[0].value;
      val = val < 1 ? 1 : val;
      $(this).val(parseInt(val) || 1);
    });
    /* eslint-disable */
    $(".minus").click(function () {
      var $input = $(this).closest(".input-number").find("input");
      var count = parseInt($input.val()) - 1;
      count = count < 1 ? 1 : count;
      $input.val(parseInt(count) || 1);
      $input.change();
      return false;
    });
    $(".plus").click(function () {
      var $input = $(this).closest(".input-number").find("input");
      var count = parseInt($input.val()) + 1;
      count = count < 1 ? 1 : count;
      $input.val(parseInt(count) || 1);
      $input.change();
      return false;
    });
  });

  // let productCount = $('.calc-price-change')
  // let allLineTotal = $('.calc-line-total')

  // productCount.each(function() {
  //     let parent = $(this).closest('.calc-item-total')
  //     let price = parent.find('.calc-price-product')[0]
  //     let input = parent.find('.calc-price-change input')
  //     let plus = parent.find('.calc-price-change .plus')[0]
  //     let minus = parent.find('.calc-price-change .minus')[0]
  //     let lineTotal = parent.find('.calc-line-total')[0]
  //     let total = $('.calc-fullprice')[0]
  //     let count = Number(input[0].value)
  //     let counter = 0

  //     price = parseFloat(price.innerHTML.replace(' ', ''))
  //     let productTotal = price * count

  //     $('.order-list-item__remove').on('click', function() {
  //         counter = counter - parseFloat($(this).closest('.calc-item-total').find('.calc-line-total')[0].innerHTML.replace(' ', ''))
  //         console.log(counter);
  //         $(this).closest('.calc-item-total').remove()
  //         let summTotal = `${String(counter).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')} р.`
  //         total.innerHTML = summTotal
  //     })

  //     function calculate() {
  //         setTimeout(function() {
  //             count = Number(input[0].value)
  //             productTotal = price * count
  //             lineTotal.innerHTML = `${String(productTotal).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')} р.`
  //             counter = 0
  //             if(total) {
  //                 for (let i = 0; i < allLineTotal.length; i++) {
  //                     let item = allLineTotal[i];
  //                     item = parseFloat(item.innerHTML.replace(' ', ''))
  //                     counter += item
  //                 }
  //                 // let summTotal = priceArray.reduce((pv, cv) => pv + cv, 0)
  //                 let summTotal = `${String(counter).replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')} р.`
  //                 total.innerHTML = summTotal

  //             }
  //         }, 10)
  //     }
  //     calculate()

  //     input.change(function() {
  //         calculate()

  //     })

  //     plus.click(function() {
  //         calculate()

  //     })
  //     minus.click(function() {
  //         calculate()

  //     })
  // })

  // window.onload = function() {
  //     // let tabBlock = document.getElementsByClassName('tab-block');
  //     // let tabControls = document.getElementsByClassName('tab-item');

  //     // tabControls.forEach(function(item) => {
  //     //     console.log(item);
  //     //     // item.addEventListener('click', function() {
  //     //     //     alert('tut!')
  //     //     // })

  //     // });
  // }
  
}
