// pPopup
export default class PragmaModal {
  constructor(selector, options = "") {
    this.el = selector || document.querySelector("[data-modal-popup]");

    this.window = document.querySelector(this.el.attributes.href.value);
    this.overlay =
      document.querySelector(options.overlayClass) ||
      this.window.querySelector("[data-modal-overlay]");

    this.init();
    window.closeAllModals = this.closeAllModals;
    window.showSuccess = this.showSuccess;
  }

  init() {
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.el.addEventListener("click", this.open);
    this.overlay.addEventListener("click", this.close);
  }

  open(e) {
    e.preventDefault(e);
    this.window.classList.add("content-show");
  }

  close() {
    this.window.classList.remove("content-show");
  }

  closeAllModals() {
    let openedModals = document.querySelectorAll(".content-show");
    if (!openedModals) return false;
    openedModals.forEach((modal) => {
      modal.classList.remove("content-show");
    });
  }
  showSuccess() {
    closeAllModals();
    let success = document.querySelector("[data-form-success]");
    let overlay = success.querySelector("[data-modal-overlay]");

    if (!success || !overlay) return false;

    overlay.addEventListener("click", function () {
      success.classList.remove("content-show");
    });

    success.classList.add("content-show");
    setTimeout(() => {
      success.classList.remove("content-show");
    }, 3000);
  }

  destroy() {
    this.close();
    this.el.removeEventListener("click", this.open);
    this.overlay.removeEventListener("click", this.close);
  }
}
